require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("chartkick/chart.js");
require("chartkick/highcharts");

import 'materialize-css/dist/js/materialize'
import 'materialize-css/dist/css/materialize.css'
import '../stylesheets/application.scss'

let ready;

ready = function() {
    $('.sidenav').sidenav();
    $('.collapsible').collapsible();
    $('.tooltipped').tooltip();
    $('select').formSelect();
    $('.modal').modal();
    $('.tabs').tabs();
    $('.materialboxed').materialbox();
    M.updateTextFields();

    var elems = document.querySelectorAll('.datepicker');
    var options = {firstDay: 1,
                        autoClose: true,
                        yearRange: [1925, 2025],
                        defaultDate: new Date().toDateString(),
                        setDefaultDate: true,
                        format: 'dd.mm.yyyy',
                        i18n: {
                            cancel: 'Відмінити',
                            clear: 'Очистити',
                            done: 'ОК',
                            previousMonth: '',
                            nextMonth: '',
                            firstDay: 0,
                            months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
                            monthsShort: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
                            weekdays: ['Понеділок', 'Вівторок', 'Середа', 'Четвер', "П'ятниця", 'Субота', 'Неділя'],
                            weekdaysShort: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', "П'ятницят", 'Субота'],
                            weekdaysAbbrev: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', "Пт", 'Сб']
                        }
                      };
    var instances = M.Datepicker.init(elems, options);

    elems = document.querySelectorAll('.timepicker');
    options = {twelveHour: false, autoClose: true};
    instances = M.Timepicker.init(elems, options);

    // show error messages for selectshow
    $("select[required]").css({position: "absolute", display: "inline", height: 0, padding: 0, width: 0});

    var elem = document.querySelector('.collapsible.expandable');
    var instance = M.Collapsible.init(elem, {
        accordion: false
    });

    // questionnaire
    $("body").on("click", "input[type=radio]", function () {
        let answer = $(this).attr('name').slice(1) + ': ' + $(this).next().html()[0];
        $('#' + $(this).attr('name')).val(answer);
    });

    // future feature
    $('.future_feature').on('click', function(d) {
        d.preventDefault();
        M.toast({html: 'Функція буде доступна найближчим часом'});
    });

    function event_param(param) {
        var status = $(param).prop('checked');
        $.ajax({
            url : $(param).data('link'),
            type : "post",
            data : { status : status }
        })
    }

    function email_fixed_status(param) {
        var status = $(param).prop('checked');
        $.ajax({
            url : $(param).data('link'),
            type : "post",
            data : { status : status }
        })
    }

    $(".switch").find("input[type=checkbox]").on("change",function() {
        event_param(this);
    });

    // disable prompt in select
    $('#viewer_region option:first').attr('disabled', 'true');
    $('#viewer_country option:first').attr('disabled', 'true');

    // set quiz answer when set any answer
    // $(".quiz_question").find("input[type=radio]").on("change",function() {
    function check_quiz_answer() {
        let quiz_question_count = $(".quiz_question").length;
        let quiz_question_checked = $(".quiz_question").find("input[type=radio]:checked").length;
        if (quiz_question_count <= quiz_question_checked) {
            let answers = '';
            for (let i = 0; i < quiz_question_count; i++) {
                answers += $($('.quiz_answer_radio:checked')[i]).data('number');
            }
            $('#quiz_answer_answer').val(answers);
            $('#save_quiz_answer').parent().removeClass('disabled');
            $('#save_quiz_answer').removeClass('disabled');
        }
    }

    $("body").on("click", ".quiz_answer", function () {
        setTimeout(check_quiz_answer, 400);
    });

    $('.export_button').on('click', function(e) {
        $(this).addClass('disabled')
    });

    // agreement checkbox when viewer create
    $('#viewer_agreement').click(function() {
       if (this.checked) {
           $('#viewer_submit').parent().removeClass('disabled')
           $('#viewer_submit').removeClass('disabled')
       } else {
           $('#viewer_submit').parent().addClass('disabled')
           $('#viewer_submit').addClass('disabled')
       }
    });

    // admin quote viewer text in messages
    $(document).on('click', '.quote_comment', function() {
        $('p#quote').removeClass('hide');
        let author_name = ''
        if ($('#message_' + $(this).data('message') + ' > .author > a').length > 0) {
            $('#quote > span.quote_author').html($('#message_' + $(this).data('message') + ' > .author > a').html() + ": ");
        } else {
            $('#quote > span.quote_author').html($('#message_' + $(this).data('message') + ' > .author').html() + ": ");
        }
        $('#quote > span.quote_text').html($('#message_' + $(this).data('message') + ' > .text').html());
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        $('#message_text').focus();
    });

    // add tags to quoted message
    $('.admin_message_form > form').submit(function() {
        if (!$('p#quote').hasClass('hide')) {
            $('#message_text').val("<p class='quote'><span class='quote_author'>" + $('span.quote_author').html() + "</span><span class='quote_text'>" + $('span.quote_text').html() + "</span></p>" + $('#message_text').val());
        }
        $('p#quote').addClass('hide');
        return true;
    });

    // submit chat message form when press Enter key
    $('textarea#message_text').on('keypress', function(e) {
        if (e.which == 13) { $('input#message_submit').click(); }
    });

    //
    //
    // tracking viewer video
    // let im_watching_now = function() {
    //     // get viewer and video ID
    //     let viewer = $('#viewer_navbar').data('viewer');
    //     let video = $('#video').data('video');
    //     if (viewer && video && YT.PlayerState.PLAYING === 1) {
    //         $.ajax({
    //             url: '/track',
    //             data: {id: viewer, video: video},
    //             success: {}
    //         });
    //     } else {
    //         stop_tracking();
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'Щось пішло не так! Необхідно перезавантажити сторінку',
    //             showCancelButton: false,
    //         }).then((result) => {
    //             if (result.isConfirmed) {
    //                 location.reload();
    //             }
    //         })
    //     }
    // };
    //
    // let timeintervals = [];
    //
    // let start_tracking = function() {
    //     stop_tracking();
    //     // run interval - ping server with interval
    //     timeintervals.push(setInterval(im_watching_now, 60000));
    // };
    //
    // let stop_tracking = function () {
    //     // clear all exists intervals, prevent multi posting
    //     for (let i = 0; i < timeintervals.length; i++) {
    //         clearTimeout(timeintervals[i]);
    //     }
    // };
    //
    // // YouTube player
    // var player;
    //
    // // create video iframe
    // let action = $('body').data('action');
    // if ((action === 'watch') || (action === 'video')) {
    //     let tag = document.createElement('script');
    //     tag.src = "http://www.youtube.com/player_api";
    //     tag.src = "https://www.youtube.com/player_api";
    //     let firstScriptTag = document.getElementsByTagName('script')[0];
    //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    //
    //     function onYouTubePlayerAPIReady() {
    //         let youtube_code = $('#youtube_player').data('youtube-code');
    //         player = new YT.Player('youtube_player', {
    //             videoId: youtube_code,
    //             events: {
    //                 'onReady': onPlayerReady,
    //                 'onStateChange': onPlayerStateChange
    //             }
    //         });
    //     }
    //
    //     setTimeout(onYouTubePlayerAPIReady, 3000);
    //
    //     function onPlayerReady() {}
    //
    //     function onPlayerStateChange(event) {
    //         if (event.data === YT.PlayerState.PLAYING) {
    //             start_tracking();
    //         } else {
    //             stop_tracking();
    //         }
    //     }
    // }

    $('#save_answers').click(function() {
        let question_count = $('#questionnaire > h5').length;
        let question_checked = $('input[type=radio]:checked').length;
        if (question_count === question_checked) {
            let result = $('#q0').val();
            for (let i = 1; i <= question_count; i++) {
                result += $('#q' + i).val();
                result += '; ';
            }
            $.ajax({
                type: 'POST',
                url: '/questionnaire',
                data: {rez: result},
                success: {}
            });
        } else {
            alert("Необхідно відповісти на всі запитання");
        }
    });

    // patch select for iOs. Stop propagation on touchend event
    $(document).click(function(){
        $('li[id^="select-options"]').on('touchend', function (e) {
            e.stopPropagation();
        });
    });

    // enable submit button for poll answer at video page
    $("body").on("click", ".poll_answer", function () {
        $('#answer_answer').val($(this).data('answer'));
        $('input[type=submit]').parent().removeClass('disabled');
        $('input[type=submit]').removeClass('disabled');
    });

    $(document).on('click', '.pay_button', function() {
        $.ajax({
            type: 'POST',
            url: '/orders',
            data: { id: $(this).data('id') },
            success: function (data) {
                $('.modal').modal('close');
                run_wayforpay(data[0], data[1], data[2], data[3], data[4], data[5]);
            }
        });
    });

    $('.pay_custom_order').on('click', function () {
        $.ajax({
            type: 'get',
            url: '/pay_custom',
            data: { id: $(this).data('custom-order-id')},
            success: function (data) {
                run_wayforpay(data[0], data[1], data[2], data[3], data[4], data[5]);
            }
        });
    })

    let run_wayforpay = function(sign, order, amount, name, price, merchant) {
        var wayforpay = new Wayforpay();
        wayforpay.run({
                merchantAccount: merchant,
                merchantDomainName: "http://e-registration.com.ua",
                authorizationType: "SimpleSignature",
                merchantSignature: sign,
                orderReference: order,
                orderDate: '1415379863',
                amount: amount,
                currency: "UAH",
                productName: 'Оплата участі в заході',
                productPrice: price,
                productCount: "1",
                language: "UA",
                serviceUrl: "https://pulmolor.herokuapp.com/wfp_responce"
                // serviceUrl: "http://video.e-registration.com.ua/wfp_responce"
            }
        );
    };
};

let clear_sidenav = function() {
    const elem = document.querySelector('.sidenav');
    const instance = M.Sidenav.getInstance(elem);
    if (instance) {
        instance.destroy();
    }
};

document.addEventListener("turbolinks:load", ready);
document.addEventListener("turbolinks:before-render", clear_sidenav);
